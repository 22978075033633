
import { cva } from 'class-variance-authority'
export default {
  name: 'Heading',
  props: {
    tag: {
      type: String,
      default: 'h1',
      validator: (value) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'div', 'span'].includes(value),
    },
    weight: {
      type: String,
      default: 'medium',
      validator: (value) => ['medium', 'extraLight'].includes(value),
    },
    text: {
      type: [String, Number],
      default: '',
    },
    theme: {
      validator: (value) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'none'].includes(value),
      default: 'none',
    },
  },
  computed: {
    style() {
      const headingsStyle = {
        h1: 'text-4.5xl md:text-6xl',
        h2: 'text-3.5xl md:text-4.5xl',
        h3: 'text-2xl md:text-3.5xl',
        h4: 'text-2xl',
        h5: 'text-base leading-tight',
        div: '',
        span: '',
      }
      const heading = cva(`font-oswald uppercase`, {
        variants: {
          weight: {
            medium: 'font-medium',
            extraLight: 'font-extralight',
          },
        },
        compoundVariants: [
          {
            tag: Object.keys(headingsStyle),
            theme: 'none',
            class: headingsStyle[this.tag],
          },
          {
            tag: Object.keys(headingsStyle),
            theme: 'h1',
            class: headingsStyle.h1,
          },
          {
            tag: Object.keys(headingsStyle),
            theme: 'h2',
            class: headingsStyle.h2,
          },
          {
            tag: Object.keys(headingsStyle),
            theme: 'h3',
            class: headingsStyle.h3,
          },
          {
            tag: Object.keys(headingsStyle),
            theme: 'h4',
            class: headingsStyle.h4,
          },
          {
            tag: Object.keys(headingsStyle),
            theme: 'h5',
            class: headingsStyle.h5,
          },
        ],
      })
      return heading({ tag: this.tag, weight: this.weight, theme: this.theme })
    },
  },
}
